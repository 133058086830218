<template>
  <div class="row flex-center min-vh-100 py-6 text-center">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
      <div class="page-block">
        <div class="page-block-item">
          <div class="card-body p-4 p-sm-5">
            <p class="page-title">Error</p>
            <p class="general-text">
              The page you're looking for is a error page.
            </p>
            <hr />
            <p class="general-text">
              Make sure the address is correct and that the page hasn't moved.
              If you think this is a mistake, contact our administrator.
            </p>
            <p class="general-text">
              <a class="btn btn-primary btn-sm mt-3" href="/vaccine">
                <span class="fas fa-home mr-2"></span>Take me home
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomErrorPage',
};
</script>
